<template>
  <component
    :is="asModal ? GenericSideMenu : 'div'"
    position="right"
    content-class=""
    :title="$t('signin.modalTitle')"
  >
    <Form
      @submit="login"
      @click="startValidate"
    >
      <div class="mb-12">
        <Field
          v-slot="{ field, meta }"
          type="email"
          name="email"
          :rules="validateEmail"
        >
          <TextInput
            v-bind="field"
            v-model="email"
            server-name="email"
            type="email"
            :label="$t('signin.emailPlaceholder')"
            :show-error="meta.validated && !meta.valid"
            :errors="meta.errors"
            autocomplete="email"
          />
        </Field>
      </div>
      <div class="mb-12">
        <Field
          v-slot="{ field, meta }"
          type="password"
          name="password"
          :rules="validateRequired"
        >
          <TextInput
            v-bind="field"
            v-model="password"
            server-name="password"
            type="password"
            :label="$t('signin.passwordPlaceholder')"
            :show-error="meta.validated && !meta.valid"
            :errors="meta.errors"
            autocomplete="current-password"
          />
        </Field>
      </div>

      <button
        :class="{ 'loading': loading }"
        class="btn w-full"
      >
        {{ $t('signin.signin') }}
      </button>

      <FormErrorMessage context="login" />
    </Form>

    <button class="btn--text" @click="setForgotPassword">
      {{ $t('signin.forgotPassword') }}
    </button>

    <NoAccountCTA />

    <transition name="fromRight">
      <GenericSideMenu
        v-if="forgotPassword"
        position="right"
        :show-back="true"
        :title="$t('signin.forgot.title')"

        @back="forgotPassword = false"
      >
        <div v-if="!resetCompleted" class="bg-lightest -m-8">
          <div class="type-sm mb-16">{{ $t('signin.forgot.text') }}</div>
          <Form @submit="requestResetPassword" @click="startValidate">
            <Field
              v-slot="{ field, meta }"
              type="email"
              name="resetEmail"
              :rules="validateEmail"
            >
              <TextInput
                v-bind="field"
                v-model="forgotPasswordEmail"
                type="email"
                :label="$t('signin.emailPlaceholder')"
                :show-error="meta.validated && !meta.valid"
                :errors="meta.errors"
                autocomplete="email"
              />
            </Field>
            <button
              :disabled="loading"
              :class="{ 'loading': loading }"
              class="btn btn w-full my-12"
            >
              {{ $t('signin.forgot.button') }}
            </button>
          </Form>
        </div>
        <div v-else class="mt-16 type-sm-medium text-center">
          {{ $t('signin.forgot.confirmationText') }}
        </div>
      </GenericSideMenu>
    </transition>
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import NoAccountCTA from '~/components/account/NoAccountCTA.vue';
import { useUserStore } from '~/store/user';
import useApiFetch from '~/composeables/useApiFetch';
import { Form, Field } from 'vee-validate';
import useValidation from '~/composeables/useValidation';
import TextInput from '~/components/form-elements/TextInput.vue';
import FormErrorMessage from '~/components/form-elements/FormErrorMessage.vue';
import { storeToRefs } from 'pinia';
import { NorceUserTypes } from '~/constants/norceCodes';
import { useGlobalContentStore } from '~/store/globalContent';
import useLinkReplacer from '~/composeables/useLinkReplacer';

const {
  validateRequired,
  startValidate,
  validateEmail,
} = useValidation();

const uiStore = useUiStore();
const userStore = useUserStore();

const { userProfile } = storeToRefs(userStore);

const forgotPassword = ref(false);
const email = ref('');
const forgotPasswordEmail = ref('');
const password = ref('');
const resetCompleted = ref(false);
const loading = ref(false);
const { apiPost, accountToken, handleApiError } = useApiFetch();
const { $t } = useNuxtApp();

const setForgotPassword = () => {
  forgotPassword.value = true;
  uiStore.setShowModalBg(true);
};

interface Props {
  asModal?: boolean;
  stayOnPage?: boolean;
  forceReload?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  asModal: true,
  stayOnPage: false,
  forceReload: false,
});

onMounted(() => {
  uiStore.clearFormErrorMsg();
});

const { manualUrl } = useLinkReplacer();

if (accountToken.value && userProfile.value) {
  navigateTo(userProfile.value.role.type === NorceUserTypes.Sales ? manualUrl('/salestool') : manualUrl('/account'));
  uiStore.closeAllModal();
}

const login = async() => {
  loading.value = true;
  let toAccountPage = !useRoute().path.includes('checkout');
  if (uiStore.stayOnPageAfterLogin || props.stayOnPage) {
    toAccountPage = false;
  }
  const ok = await userStore.logIn({
    email: email.value,
    password: password.value,
    toAccountPage,
  });
  if (!ok) {
    handleApiError('login', true);
  }
  loading.value = false;
  if (ok && props.forceReload) {
    window.location.reload();
  }
};

const globalContent = useGlobalContentStore();

const requestResetPassword = async() => {
  loading.value = true;
  const payload = {
    email: forgotPasswordEmail.value,
    url: `${window.location.protocol}//${window.location.host}/${globalContent.getPrependPathForManualLinksWithTrailingSlash}account/resetpassword/${encodeURIComponent(forgotPasswordEmail.value)}`,
  };

  const res = await apiPost<string>(
    '/password/forgot',
    payload
  );
  if (res) {
    resetCompleted.value = true;
  } else {
    handleApiError();
  }
  loading.value = false;
};
</script>

<style scoped lang="postcss">
</style>
